import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Le Tableau de courbes plutôt que le Tableau de chiffres`}</h1>
    <h3>{`La Table de Valeurs`}</h3>
    <p>{`La version la plus élémentaire de la présentation de donnés est le  catalogue de valeurs. L'ambition est de fournir de manière rapide la réponse aux questions les plus élémentaires: `}<em parentName="p">{`Quel est le montant des ventes en France en Avril ?`}</em>{`
La table est organisée pour fournir avec toutes les décimales, au centime près, toutes les réponses selon un format très touffu qui décourage la moindre tentative de dégager visuellement des informations de tendance et de comparaison. `}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.263803680981596%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAA0UlEQVQoz12S2wqEMAxE+/9fKD75Ikq9XxBBJbsnMEtWYch0EpJMayqKwu77tmmabFkW6/ve0XWdx3mebRxH58MwOCdHvSKaapJ9P0jO2Zqm+SWAOJFmUd+2zTU1hYN0nqc3Yrt1XX0jgbNAXjVEGkZNeirL0qeoSJNkMVqCA3LSpUl3y1qdTbWdihikM1zxOA5fgKbSQdr33dq29SQcUU2jNZ3F0d8aSGxW17WL13X5pnoENY+2ZE12owN/FCxXVWU8zvM8f78KF/2+p5gD76EfFWBf0nPo9jQAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Courbes2",
          "title": "Courbes2",
          "src": "/static/3ea32b10b099cdc1f860c79ad6534e0a/a6d36/Courbes2.png",
          "srcSet": ["/static/3ea32b10b099cdc1f860c79ad6534e0a/222b7/Courbes2.png 163w", "/static/3ea32b10b099cdc1f860c79ad6534e0a/ff46a/Courbes2.png 325w", "/static/3ea32b10b099cdc1f860c79ad6534e0a/a6d36/Courbes2.png 650w", "/static/3ea32b10b099cdc1f860c79ad6534e0a/e548f/Courbes2.png 975w", "/static/3ea32b10b099cdc1f860c79ad6534e0a/0a867/Courbes2.png 986w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Il s'agit ici plus d'un reporting à plat qu'un outil d'aide à la décision. Elle ne devrait plus être présentée à des humains au 21 siècle. `}</p>
    <h3>{`Le Tableau digeste`}</h3>
    <p>{`Une version plus humaine consiste à présenter les nombres sous une forme plus digeste privilégiant l'ordre de grandeur à la précision. Deux chiffres significatifs sont largement suffisants pour discriminer de 1 à 100. L'utilisation d'unités adaptées permet de limiter le nombre de zéros et renforce l'appréciation visuelle des différences. Il est même possible de d’omettre les valeurs les plus faibles.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.306748466257666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABJUlEQVQoz32SyYrDQAxE/f/fZsjFJ68kOXjfkniPUeZpUDBmGIOoLqm7tNlxXVfSNJV936UoCun7Xuq6lqZpFOFd10nbtuqDg1VVffkx7rzfb1mWRZ2v10sej4cMw6AGH8dR8czNZ/5pmrQgR34+KsnzXIPP51OD8zwrkgyRIwfNzL9t268gFZVlqaUTQBQfwtixCuOg3bEYhobjeZ5cr1cN2iyYD1WTyGZjcz3OELM4+G2ZynhsomSlTZCYze7MMTjvaJ3lqiBiGPP5ayn/JbAlIYiGQ7u3202SJNHLtMSCKB+z9jnzwEaRZZnes9YxuMP/RwYu2C+0rquicTaID7T42dBBVFvmQAV89/tdfN+XIAgkiiI9h2GoHDxzEF8cx3K5XOQDI1b6Xmo7iX0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Courbes1",
          "title": "Courbes1",
          "src": "/static/8aa163a5e9d152003e307d4869b31d72/a6d36/Courbes1.png",
          "srcSet": ["/static/8aa163a5e9d152003e307d4869b31d72/222b7/Courbes1.png 163w", "/static/8aa163a5e9d152003e307d4869b31d72/ff46a/Courbes1.png 325w", "/static/8aa163a5e9d152003e307d4869b31d72/a6d36/Courbes1.png 650w", "/static/8aa163a5e9d152003e307d4869b31d72/e548f/Courbes1.png 975w", "/static/8aa163a5e9d152003e307d4869b31d72/3c492/Courbes1.png 1300w", "/static/8aa163a5e9d152003e307d4869b31d72/df438/Courbes1.png 1556w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <h3>{`Le Tableau de Courbes`}</h3>
    <p>{`Mais si l'objectif est de comprendre, piloter, réagir, maximiser les ventes, alors il est souhaitable de passer à d'autres types de représentations. L’œil est très efficace pour reconnaitre des schémas, surveiller les tendances, prolonger visuellement une trajectoire, détecter les points aberrants,... Utilisons le au maximum en prenant le point de vue extrême opposé aux versions précédentes, un tableau sans `}<strong parentName="p">{`aucun`}</strong>{` chiffre.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "46.012269938650306%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABR0lEQVQoz4VS6c6CMBDs+7+ZCQQQEiSKBx4cHkRNPUA7djZg8PvzbbJpt53O7uxWGWM0rNnVcG3bFpPJBI/HgyGapoHjODgejxIfDgeMRiOMx2O+we12g+u6CMMQz+cTymK+hAS8328h4dqdC/nr9fqJ6cSlaYo4jhFFEabTKVSe57oDS4VDI2lX+JdsmIxqttstqqpCWZbiarVaaYKI11oLYLfbYb1e43K5/CRgVbzfbDbIskyI2Ir9fi+t4PqVTMLz+SxAPuDDoihgE2K5XIpbNULC856U+6H/EFLC/X4XZzV1XctD3/eFjBXwjoPoMdwPXdmJas/zEASBEA6NU5vNZjJlElPBf6bsF9D8Atfr1QwHQLNnSJIEi8WCCUX+6XQS2ZTLtvwdoLKl664yQxkE9w2ms+l9TNmsmF9lPp9LzN5yT2eSD3U4rOv0JlRaAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Courbes0",
          "title": "Courbes0",
          "src": "/static/4430556a0959c3c16ae1aa5e7ed0b92f/a6d36/Courbes0.png",
          "srcSet": ["/static/4430556a0959c3c16ae1aa5e7ed0b92f/222b7/Courbes0.png 163w", "/static/4430556a0959c3c16ae1aa5e7ed0b92f/ff46a/Courbes0.png 325w", "/static/4430556a0959c3c16ae1aa5e7ed0b92f/a6d36/Courbes0.png 650w", "/static/4430556a0959c3c16ae1aa5e7ed0b92f/6a6e9/Courbes0.png 826w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Les courbes visualisent l'historique des grandeurs au cours de l'année. La zone grisée en arrière plan indique le cumulé à date.  `}</p>
    <br />
    <p>{`La France est la première source de revenu, suivi de l'Europe en baisse et des US en croissance.`}<br parentName="p"></br>{`
`}{`Les ordres de grandeur deviennent visibles et l'efficacité de la publicité sur le chiffre d'affaires apparait en pleine lumière. Il n'est même plus utile de connaitre l'unité de l'axe vertical k€, M€ ou $, aucune importance.`}</p>
    <br />
    <p>{`Soudain le lecteur se met à poser des question plus intelligentes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Pourquoi la publicité sur la zone Asie est-elle si peu efficace ?`}</strong>{` `}</li>
      <li parentName="ul"><strong parentName="li">{`Que se passe t-il en zone Europe ?`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`L’accroissement relatif de publicité est il une réaction aux mauvais résultats ou l'effet mécanique de la baisse de CA ?`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`...`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      